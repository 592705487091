import React, { useEffect } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useAuth } from "../context/AuthContext";
import Seo from "../components/meta/Seo";
import Builder from "../utilities/Builder";
import { useNav } from "../context/NavContext";

const PageTemplate = ({ data, pageContext, location }) => {
  const { settings, page } = data;
  const { Blocks, locale } = page;
  const { setCurrentLocation } = useNav();

  useEffect(() => {
    setCurrentLocation(location);
  }, [location]);

  return (
    <Layout
      settingsData={settings}
      pageData={page}
      className={`${page.slug} bg--${page.MainColor || "White"}`}
    >
      <Seo
        page={page}
        curPage={`ESG Rating | ${page.PageTitle} `}
        pageType={"page"}
      />
      <Helmet htmlAttributes={{ lang: pageContext.lang.split("-")[0] }}>
        <title>ESG Rating | {page.PageTitle}</title>
      </Helmet>
      <Builder Blocks={Blocks} pageLang={page.locale} data={data} />
    </Layout>
  );
};

export const query = graphql`
  query pageQuery($lang: String, $slug: String) {
    settings: strapiSetting(locale: { eq: $lang }) {
      Main {
        SiteDescription
        SiteShortTitle
        SiteTitle
        Logo {
          alternativeText
          localFile {
            extension
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      Header {
        ButtonLinks {
          LinkIcon {
            alternativeText
            localFile {
              extension
              childrenImageSharp {
                gatsbyImageData
              }
            }
          }
          LinkText
          LinkUrl {
            slug
            locale
            internal {
              type
            }
          }
        }
        Column1Sublinks {
          LinkText
          page {
            slug
            locale
            internal {
              type
            }
          }
        }
        Column2Sublinks {
          LinkText
          page {
            slug
            locale
            internal {
              type
            }
          }
        }
      }
      Footer {
        Address {
          address {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        FooterMenu {
          Label
          Slug {
            internal {
              type
            }
            locale
            slug
          }
        }
        Newsletter {
          Title
          Subtitle
        }
        Mobile {
          Number
        }
        Email {
          Address
        }
      }
    }
    page: strapiPage(slug: { eq: $slug }, locale: { eq: $lang }) {
      PageTitle
      internal {
        type
      }
      locale
      slug

      localizations {
        data {
          attributes {
            locale
            slug
          }
        }
      }
      Meta {
        PageMetaTitle
        PageMetaShortTitle
        PageMetaDescription
        PageMetaImage {
          localFile {
            url
          }
          width
          height
          alternativeText
        }
      }
      MainColor
      Blocks {
        ... on STRAPI__COMPONENT_COMPONENTS_EVENT_SECTION_WITH_HEXAGON {
          strapi_component
          Column1 {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
          Column2 {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
          HexagonText {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
          HexagonImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          HexagonOnTheRight
        }

        ... on STRAPI__COMPONENT_COMPONENTS_TEXT_BOX_WITH_SHADOW {
          strapi_component
          BoxDescription: Description {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
          BackgroundShadow
          NegativeMarginTop
          BackgroundColor
          Icon
          Button {
            ButtonLabel
            ButtonColor
            ButtonSlug {
              slug
              locale
              internal {
                type
              }
            }
          }
          TextCenter
        }

        ... on STRAPI__COMPONENT_SECTIONS_VALUES_GRID {
          strapi_component
          Title
          ValueCard {
            IncludeCheck
            ValueDescription {
              data {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }

        ... on STRAPI__COMPONENT_FORMS_CONTACT {
          strapi_component
          MapEmbedUrl
          BackgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          ContactInfo {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }

        ... on STRAPI__COMPONENT_COMPONENTS_IMAGE_WITH_RICH_TEXT_COLUMNS {
          strapi_component
          ImageVerticalPosition
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          BackgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          ColumnDescription: Description {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
          Button {
            ButtonLabel
            ButtonColor
            ButtonSlug {
              slug
              locale
              internal {
                type
              }
            }
          }
        }

        ... on STRAPI__COMPONENT_COMPONENTS_NARROW_BACKGROUND {
          strapi_component
          BackgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }

        ... on STRAPI__COMPONENT_COMPONENTS_BG_AND_RICHTEXT {
          strapi_component
          Title
          BackgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          Column1 {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
          Column2 {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
          ButtonColor
          ButtonLabel
          ButtonSlug {
            slug
            locale
            internal {
              type
            }
          }
          ButtonPosition
        }

        ... on STRAPI__COMPONENT_SECTIONS_TESTIMONIALS {
          strapi_component
          Title
        }

        ... on STRAPI__COMPONENT_SECTIONS_TRUSTED_PARTNER {
          strapi_component
          PartnersTitle
          PartnersLogos {
            alternativeText
            localFile {
              extension
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }

        ... on STRAPI__COMPONENT_COMPONENTS_WEB_SECTION {
          strapi_component
          ImageOnTheRight
          WebSectionDescription: Description {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  transformOptions: { fit: CONTAIN }
                  layout: FIXED
                )
              }
            }
          }
          Background {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          MaxWidth {
            Size
          }
        }

        ... on STRAPI__COMPONENT_COMPONENTS_HERO {
          HeroTitle
          BackgroundPosition {
            Position
          }
          Background {
            localFile {
              url
              extension
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          BackgroundVideo {
            localFile {
              url
            }
          }
          IncludeEventureLogo
          strapi_component
        }

        ... on STRAPI__COMPONENT_SECTIONS_TESTIMONIALS {
          strapi_component
          Title
          VideoBackground {
            localFile {
              url
            }
          }
          VideoPoster {
            alternativeText
            localFile {
              extension
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }

        ... on STRAPI__COMPONENT_COMPONENTS_STICKY_SECTIONS {
          strapi_component
          SingleStickySection {
            Video {
              localFile {
                url
              }
            }
            TopImage {
              alternativeText
              localFile {
                extension
                url
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            BackgroundImage {
              alternativeText
              localFile {
                extension
                url
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            Column1 {
              data {
                childMarkdownRemark {
                  html
                }
              }
            }
            Column2 {
              data {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    }
    allTestimonials: allStrapiTestimonial(filter: { locale: { eq: $lang } }) {
      nodes {
        Description {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

export default PageTemplate;
